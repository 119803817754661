








































































/**
 * TASK JIRA LINK (copy & paste here)
 *
 * Created at : 20 Sep 2022           Updated at : 20 Sep 2022
 * Created by : Risman Abdilah        Updated by : Risman Abdilah
 */

import Vue from "vue";
import $ from "jquery";
import filters from "@/filters";
import Component from "vue-class-component";
import { Action, Getter, State } from "vuex-class";

/**
 * Model imports
 */
import {
  NewsModel,
  NewsPaginationModel,
} from "@/models/information/information.model";

/**
 * Components imports
 */
import NavbarComponent from "@/components/landing/Navbar.vue";
import JoinComponent from "@/components/landing/Join.vue";
import FooterComponent from "@/components/landing/Footer.vue";

@Component({
  filters,
  components: {
    NavbarComponent,
    JoinComponent,
    FooterComponent,
  },
})
export default class News extends Vue {
  /* VUEX */

  /* ACTIONS */
  @Action("actionPaginationNews", { namespace: "information" })
  private actionPaginationNews: any;

  /* STATE */
  @State("array_news", { namespace: "information" })
  private array_news!: Array<NewsModel>;
  @State("pagination_news", { namespace: "information" })
  private pagination!: NewsPaginationModel;

  /* GETTERS */
  @Getter("firstColor")
  private firstColor!: { text: string; bg: string; border: string };
  @Getter("twoColor")
  private twoColor!: { text: string; bg: string; border: string };

  /* INITIALIZATION */

  private get firstTextColor() {
    return this.firstColor.text;
  }

  private get twoTextColor() {
    return this.twoColor.text;
  }

  private get isDisablePrev() {
    return this.pagination.currentPage === 1;
  }

  private get isDisableNext() {
    return this.pagination.pageCount === this.pagination.currentPage;
  }

  private mounted() {
    $(document).ready(function () {
      $("html,body").animate({ scrollTop: 0 }, 800);
    });
  }

  /* METHODS */
  private onClickNews(news: NewsModel) {
    this.$router.push({
      name: "newsDetail",
      params: { id: news.id.toString() },
    });
  }

  private onClickPrevious() {
    if (this.isDisablePrev) return;

    const newPage = this.pagination.currentPage - 1;
    this.onPaginate(newPage);
  }

  private onClickNext() {
    if (this.isDisableNext) return;

    const newPage = this.pagination.currentPage + 1;
    this.onPaginate(newPage);
  }

  private onPaginate(newPage: number) {
    this.pagination.currentPage = newPage;
    this.actionPaginationNews({
      params: { page: this.pagination.currentPage },
    });
  }
}
